// import { Loading } from 'element-ui';
import { checkUrl } from '@/utils/utils'
import axios from 'axios';
import { Message } from 'element-ui';
import router from './router';
axios.defaults.timeout = 10000;

window.$glob = {
  url: '',
  group: '',
  params: {},
  query: {},
  header: {}
};
// function getGlobParams () {
//   var query = window.location.search.substring(1);
//   query = query.split("&");
//   query.forEach(ele => {
//     var pair = ele.split("=");
//     window.$glob.params[pair[0]] = pair[1]
//   })
// }
// getGlobParams();
// axios.defaults.timeout = 30000;
//返回其他状态吗
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500; // 默认的
};
//跨域请求，允许保存cookie
let loadingInstance = '';
axios.defaults.withCredentials = true;
axios.interceptors.request.use(config => {
  config.headers['X-Access-Token'] = localStorage.getItem('tokens');

  if (!checkUrl(config.url)) config.url = window.$glob.url + config.url;
  let header = window.$glob.header || {};
  config.headers = Object.assign(config.headers, header);
  let data = window.$glob.query || {}
  let key;
  if (['get', 'delete'].includes(config.method)) {
    key = "params"
  } else if (['post', 'put'].includes(config.method)) {
    key = "data"
  }
  if (typeof (config[key]) === 'object') {
    config[key] = Object.assign(config[key] || {}, data)
  }
  if (config.headers.proxy) {
    let headers = {}
    for (let ele in config.headers) {
      if (typeof (config.headers[ele]) !== 'object') headers[ele] = config.headers[ele]
    }
    let form = {
      url: config.url,
      method: config.method,
      headers: headers
    }
    form[key] = config[key]
    config.url = window.$website.url + '/visual/proxy'
    config.method = 'post';
    config.data = form
  }
  return config
}, error => {
  return Promise.reject(error)
});
//HTTPrequest拦截
axios.interceptors.response.use(config => {
  // loadingInstance.close();
  if(router.history.current.hash.slice(8)!=''){
    localStorage["tokens"] = router.history.current.hash.slice(8);
  }
  let username = localStorage.getItem("username")
  // if(!router.history.current.fullPath.includes('?token')){
    if(router.history.current.fullPath=="/"){


    if(username!="admin"){
      router.push({ path: "/UserLayout" })
    }
  }
  if (router.history.current.fullPath!='/UserLayout') {
    sessionStorage.setItem("routeUrl",router.history.current.fullPath);
  }
  if(!localStorage.getItem('tokens')){

    router.push({ path: "/UserLayout" })
  }else{
    if(router.history.current.name=='build'||router.history.current.name=='view'){
      if(!localStorage.getItem('tokens')){
        router.push({ path: "/UserLayout" })
      }else if(config.data.message == "Token失效，请重新登录"){
        router.push({ path: "/UserLayout" })
      }
    }
  }
  return config;
}, error => {
  // loadingInstance.close();
  return Promise.reject(new Error(error));
})

export default axios;
