
export default {
  COMPNAME: "avue-echart-",
  NAME: "list",
  DEAFNAME: 'item',
  password: 'avue_data'

}
export const website = window.$website
// export const url = website.url
export const url = 'https://dapinghouduan.xlzy56.com/blade-visual'//生产
